/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from 'react'
import lottie from 'lottie-web'
import { useStaticQuery, graphql } from 'gatsby'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'
import { useInView } from 'react-intersection-observer'
import FacebookIcon from '../../../static/footer/social-icon/facebook-icon.svg'
import EmailIcon from '../../../static/footer/social-icon/email-icon.svg'
import WhatsAppIcon from '../../../static/footer/social-icon/whatsapp-icon.svg'

import footerAnimLeft1 from '../../../static/animations/footer-animations/hand-1-left.json'
import footerAnimLeft2 from '../../../static/animations/footer-animations/hand-2-left.json'
import footerAnimRight1 from '../../../static/animations/footer-animations/hand-1-right.json'
import footerAnimRight2 from '../../../static/animations/footer-animations/hand-2-right.json'
import footerAnimPaws from '../../../static/animations/footer-animations/paws.json'
import { filterLanguage } from '../utils/language'
import './_footer.scss'

const Footer = ({ location }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const togglelan = (e) => {
    e.preventDefault()
    setDropdownOpen(!dropdownOpen)
  }
  const data = useStaticQuery(graphql`
    {
      allContentfulFooter {
        nodes {
          id
          node_locale
          footerTitle
          footerDescription {
            childContentfulRichText {
              html
            }
          }
          footerEmail
          footerEmailLink
          footerFacebookLink
          footerTelephoneLink
        }
      }
    }
  `)

  const { nodes } = data.allContentfulFooter
  const nodeslang = filterLanguage(location, nodes)
  const firstAnimationContainer = createRef()
  const secondAnimationContainer = createRef()
  const thirdAnimationContainer = createRef()
  const fourthAnimationContainer = createRef()
  const fifthAnimationContainer = createRef()
  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (!firstAnimationContainer || !secondAnimationContainer || !thirdAnimationContainer || !fourthAnimationContainer || !fifthAnimationContainer) {
      return
    }
    const firstAnim = lottie.loadAnimation({
      container: firstAnimationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: footerAnimLeft1,
    })

    const secAnim = lottie.loadAnimation({
      container: secondAnimationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: footerAnimLeft2,
    })

    const thirdAnim = lottie.loadAnimation({
      container: thirdAnimationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: footerAnimRight1,
    })

    const fourthAnim = lottie.loadAnimation({
      container: fourthAnimationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: footerAnimRight2,
    })

    const fifthAnim = lottie.loadAnimation({
      container: fifthAnimationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: footerAnimPaws,
    })

    if (inView === true) {
      firstAnim.play()
      secAnim.play()
      thirdAnim.play()
      fourthAnim.play()
      fifthAnim.play()
    }

    return () => {
      firstAnim.destroy()
      secAnim.destroy()
      thirdAnim.destroy()
      fourthAnim.destroy()
      fifthAnim.destroy()
    }
  }, [firstAnimationContainer, secondAnimationContainer, thirdAnimationContainer, fourthAnimationContainer, fifthAnimationContainer])


  return (
    <footer id="work-together">
      <div className="container-fluid" ref={ref}>
        <div className="row">
          <div className="col-md-2 p-0 footer-image">

            <div className="animation-container" ref={firstAnimationContainer} />
            <div className="animation-container" ref={secondAnimationContainer} />

          </div>
          {nodeslang.map((item) => (
            <div
              id="contact"
              className="col-md-8 text-center footer-text"
              key={item.id}
            >
              <h1>{item.footerTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.footerDescription.childContentfulRichText.html,
                }}
              />
              <p className="footer-email">
                <strong>{item.footerEmail}</strong>
              </p>
              <ul className="footer-social-icons">
                <li>
                  <a href={item.footerFacebookLink} target="_blank">
                    <img
                      className="img-fluid lazyload"
                      src={FacebookIcon}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href={`mailto:${item.footerEmailLink}`} target="_blank">
                    <img
                      className="img-fluid lazyload"
                      src={EmailIcon}
                      alt="Email"
                    />
                  </a>
                </li>
                <li>
                  <a href={item.footerTelephoneLink} target="_blank">
                    <img
                      className="img-fluid lazyload"
                      src={WhatsAppIcon}
                      alt="WhatsApp"
                    />
                  </a>
                </li>
                <ul className="languages-footer">
                  <Dropdown nav isOpen={dropdownOpen} toggle={togglelan}>
                    <DropdownToggle caret className="">
                      Language
                    </DropdownToggle>
                    <DropdownMenu>
                      <a
                        href={
                          typeof window !== 'undefined'
                          && `https://banbanmedia.co${window.location.href
                            .split(window.location.host)[1]
                            .replace('/fi', '')}`
                        }
                      >
                        English
                      </a>

                      <hr />

                      <a
                        href={
                          typeof window !== 'undefined'
                          && `https://banbanmedia.fi/${window.location.href
                            .split(window.location.host)[1]
                            .replace('/fi', '')}`
                        }
                      >
                        Finnish
                      </a>
                    </DropdownMenu>
                  </Dropdown>
                </ul>
              </ul>
            </div>
          ))}

          <div className="col-md-2 p-0 footer-image">

            <div className="animation-container" ref={thirdAnimationContainer} />
            <div className="animation-container" ref={fourthAnimationContainer} />

          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-10">

            <div style={{ height: '150px', width: '232px' }} className="animation-container m-auto" ref={fifthAnimationContainer} />

          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
