import React from "react";
import PropTypes from "prop-types";
import SecondaryHeader from "../secondary-header";
import Footer from "../footer";

import "bootstrap/scss/bootstrap.scss";
import "font-awesome/scss/font-awesome.scss";
import "./_layout.scss";
import "./_animations.scss";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const SecondaryLayout = ({ children, location }) => (
  <>
    <SecondaryHeader location={location} />
    <main>{children}</main>
    <Footer location={location} />
  </>
);

SecondaryLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecondaryLayout;
