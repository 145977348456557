/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable global-require */
import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Collapse, Navbar, NavbarBrand, Nav, NavItem } from "reactstrap";
import Logo from "../../../static/logo/banban-logo.png";
import "./_secHeader.scss";
import { filterLanguage, location } from "../utils/language";

const SecondaryHeader = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogCategory {
        nodes {
          node_locale
          id
          title
        }
      }
    }
  `);

  const { nodes } = data.allContentfulBlogCategory;
  const nodeslang = filterLanguage(location, nodes);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const searchToggle = () => setIsSearchOpen(!isSearchOpen);

  const isActive = ({ isCurrent }) =>
    isCurrent
      ? { className: "nav-link sec-nav-link ml-lg-4 activeClass" }
      : null;

  return (
    <header id="secondary-header">
      <div className="container">
        <Navbar className="secondary-nav" color="white" light expand="lg">
          <NavbarBrand href={!location ? "/" : "/fi"}>
            <img
              className="img-fluid secondary-header-logo"
              src={Logo}
              alt="Header Logo"
            />
          </NavbarBrand>
          <button
            onClick={toggle}
            className="navbar-toggler border-0"
            type="button"
          >
            {!isOpen && (
              <img
                className="img-fluid"
                src={require("../../../static/header/open-menu-icon.svg")}
                alt="Open Menu"
              />
            )}
            {isOpen && (
              <img
                className="img-fluid"
                src={require("../../../static/header/close-menu-icon.svg")}
                alt="Close Menu"
              />
            )}
          </button>
          <Collapse isOpen={isOpen} navbar>
            <div className="tab-mob-search-field">
              <div className="form-group mob-search">
                <span className="fa fa-search form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div>
            <Nav className="ml-auto" navbar>
              {nodeslang.map((category, index) => (
                <NavItem className="secondary-nav-item" key={index}>
                  <Link
                    getProps={isActive}
                    className="nav-link sec-nav-link ml-md-4"
                    to={
                      !location
                        ? `/blogs/${category.title
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`
                        : `/fi/blogs/${category.title
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`
                    }
                  >
                    {category.title}
                    <hr />
                  </Link>
                </NavItem>
              ))}
            </Nav>
            <div className="desktop-search-field">
              <div className="ml-md-4 secondary-header-search-wrap d-flex">
                <img
                  className="img-fluid"
                  onClick={searchToggle}
                  src={require("../../../static/blogs/icons/search-icon.svg")}
                  alt="search icon"
                />
                {isSearchOpen && (
                  <div className="search-container">
                    <form action="">
                      <input
                        className="search-field"
                        type="text"
                        placeholder="| Search BanBan"
                        name="search"
                      />
                    </form>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default SecondaryHeader;
