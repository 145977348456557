//import { parseDomain, fromUrl } from "parse-domain";
import extractDomain from "extract-domain";
export const filterLanguage = (location, data) => {
  var nodeslang;

  var result = "";
  // try {
  //   result =
  //     typeof window !== "undefined"
  //       ? extractDomain(window.location.href)
  //       : "co";
  // } catch (e) {
  //   result = "co";
  // }

  //const { StopLevelDomains } = parseDomain(location.href);
  // if (!!location && !!location.href) {
  //   result = location.hostname;
  //   console.log(location);
  // }
  // try {
  //   if (result.split(".")[result.split(".").length - 1] === "fi") {
  //     result = "fi";
  //   } else {
  //     result = "co";
  //   }
  // } catch (e) {
  //   result = "co";
  // }

  nodeslang = data.filter((lang) => lang.node_locale === "en-US");

  return nodeslang;
};
